import React from "react";
import { useTranslation } from "react-i18next";
import { Box, Flex, Text } from "@graphite/uneon";

import blob1Png from "../../../images/bubleright.svg";
import blob2Png from "../../../images/bluecircle.svg";
import blob3Png from "../../../images/bubledown.svg";
import cube from "./images/logo-cube-shadow.svg";

const blobSx = {
	position: "absolute",
	top: "50%",
	left: "50%",
};

const blob1Sx = {
	...blobSx,
	animation: "shimmy1 20s infinite",
	animationDirection: "alternate",
	"@keyframes shimmy1": {
		"0%": {
			transform: "translate(53%,-44%)",
		},
		"50%": {
			transform: "translate(15%,-107%)",
		},
		"100%": {
			transform: "translate(-134%,-109%)",
		},
	},
};

const blob2Sx = {
	...blobSx,
	animation: "shimmy2 20s infinite",
	animationDirection: "alternate",
	"@keyframes shimmy2": {
		"0%": {
			transform: "translate(-131%,-110%)",
		},
		"50%": {
			transform: "translate(-150%,-58%)",
		},
		"100%": {
			transform: "translate(-120%,4%)",
		},
	},
};

const blob3Sx = {
	...blobSx,
	animation: "shimmy3 20s infinite",
	animationDirection: "alternate",

	"@keyframes shimmy3": {
		"0%": {
			transform: "translate(-354px, 18px)",
		},
		"50%": {
			transform: "translate(40px, 35px)",
		},
		"100%": {
			transform: "translate(101px, -165px)",
		},
	},
};

const text = {
	position: "relative",
	marginBottom: ["20px", "0"],
	maxWidth: "210px !important",
};

const textsSx = [
	{
		...text,
		transform: ["none", "translate(0%,0%)"],
		gridColumnStart: ["col-start !important", "2 !important"],
		gridColumnEnd: ["4 !important", "6 !important"],
		marginLeft: ["0", "0", "20%"],
	},
	{
		...text,
		transform: ["none", "translate(10%,0%)"],
		gridColumnStart: ["4 !important", "8 !important", "9 !important"],
		gridColumnEnd: ["7 !important", "12 !important"],
	},
	{
		...text,
		transform: ["none", "translate(0%,0%)"],
		gridColumnStart: "col-start !important",
		gridColumnEnd: ["4 !important", "5 !important"],
		marginLeft: ["0", "0", "20%"],
	},
	{
		...text,
		transform: ["none", "translate(0%,0%)"],
		gridColumnStart: ["4 !important", "9 !important", "10 !important"],
		gridColumnEnd: ["7 !important", "13 !important"],
		marginTop: ["0", "0", "10px"],
	},
	{
		...text,
		transform: ["none", "translate(0%,0%)"],
		gridColumnStart: ["col-start !important", "2 !important"],
		gridColumnEnd: ["4 !important", "6 !important"],
		marginLeft: ["0", "0", "20%"],
	},
	{
		...text,
		transform: ["none", "translate(10%, 0%)"],
		gridColumnStart: ["4 !important", "8 !important", "9 !important"],
		gridColumnEnd: ["7 !important", "13 !important"],
	},
];

const Blobs = () => {
	const { t } = useTranslation("translation", { useSuspense: false });

	return (
		<Box
			variant="gridcontainer"
			padding="0"
			p="0 !important"
			sx={{
				gridGap: ["40px 35px", "80px 35px", "10vh 35px"],
				backgroundImage: ["none", `url(${cube})`],
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover",
				backgroundPosition: "50% 50%",
				position: "relative",
				gridColumn: [
					"span 6 !important",
					"span 12 !important",
					"span 8 !important",
				],
				height: "fit-content",
				maxWidth: "1000px",
			}}
			mt={["67px", "50px", 0]}
			mb={["60px", "100px", 0]}
		>
			<Box as="img" src={blob1Png} sx={blob1Sx} alt="blob" />
			<Box as="img" src={blob2Png} sx={blob2Sx} alt="blob" />
			<Box as="img" src={blob3Png} sx={blob3Sx} alt="blob" />
			{[1, 2, 3, 4, 5, 6].map((x) => (
				<Flex key={`blobs-${x}`} sx={textsSx[x - 1] || {}}>
					<Box>
						<Text
							variant="headlinemd"
							color="text.primaryalt"
							mb="10px"
						>
							{t(`ecosystem.text${x}.heading`)}
						</Text>
						<Text variant="headlinesm" color="text.primary">
							{t(`ecosystem.text${x}.main`)}
						</Text>
					</Box>
				</Flex>
			))}
		</Box>
	);
};

export default React.memo(Blobs);
