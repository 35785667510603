import React from "react";
import styled from "@emotion/styled";
import { Box, Text, Button } from "@graphite/uneon";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

import Blobs from "./Blobs";

const Wrapper = styled(Box)`
	position: relative;
	overflow: hidden;
`;

const BackgroundImg = styled(Img)`
	position: absolute !important;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
`;

const textBox = {
	gridColumn: [
		"span 6 !important",
		"span 12 !important",
		"span 4 !important",
	],
	textAlign: ["left", "center", "left"],
};

const Content = () => {
	const { bg3, site }: any = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					appUrl
				}
			}
			bg3: file(relativePath: { eq: "bg3.jpg" }) {
				...videoImage
			}
		}
	`);

	const appUrl = site.siteMetadata?.appUrl || "/";

	return (
		<Wrapper>
			<BackgroundImg
				fluid={{
					...bg3.childImageSharp.fluid,
					media: "(max-width: 1920px)",
				}}
			/>
			<Box
				variant="gridcontainer"
				pt={["130px", "100px"]}
				pb={[0, "80px", "135px"]}
				sx={{
					zIndex: 2,
					position: "relative",
					alignItems: "center",
				}}
			>
				<Box sx={textBox}>
					<Text variant="sectionmd" color="text.accent">
						Web ecosystem
					</Text>
					<Text
						variant="hero3"
						color="text.secondary"
						mt="40px"
						sx={{ whiteSpace: "nowrap" }}
					>
						One Vision
					</Text>
					<Text
						variant="hero3"
						color="text.primaryalt"
						sx={{ whiteSpace: "nowrap" }}
					>
						One Solution
					</Text>
					<Text
						variant="bodylg"
						color="text.primary"
						mt="30px"
						ml={["0", "8vw", "0"]}
						mr={["0", "8vw", "0"]}
					>
						From an occasional freelance gig to a full-fledged web
						design studio – Graphite has all the tools necessary to
						get the job done. Everything you need – packed in one
						versatile reliable solution.
					</Text>
					<Button
						as="a"
						display="inline-flex"
						mt={["58px", "58px", "120px"]}
						href={`${appUrl}signup`}
						variant="warning.rounded.lg"
						data-link="ecosystem"
					>
						Get started
					</Button>
				</Box>
				<Blobs />
			</Box>
		</Wrapper>
	);
};

export default React.memo(Content);
