import React from "react";
import styled from "@emotion/styled";
import { Box } from "@graphite/uneon";

import backgroundUrl from "./background.svg";

const Shape = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	z-index: 0;
	width: 48px;
	height: 48px;
	background-image: url(${backgroundUrl});
	background-repeat: no-repeat;
	cursor: pointer;
	transition: all 0.25s linear;
	transform: ${({ rotateSvg }) => (rotateSvg ? "rotate(180deg)" : "none")};
	opacity: ${({ isShow }) => (isShow ? "1" : ".5")};

	& > svg {
		margin-left: 1px;
	}

	&:hover {
		transform: ${({ rotateSvg, isShow }) => {
			if (!isShow) return rotateSvg ? "rotate(180deg)" : "none";

			return rotateSvg ? "rotate(180deg) scale(1.05)" : "scale(1.05)";
		}};
	}
`;

export default React.memo(Shape);
