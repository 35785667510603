import React from "react";
import styled from "@emotion/styled";
import { Flex, Box, Text, Button } from "@graphite/uneon";
import { graphql, useStaticQuery } from "gatsby";
import { useInView } from "react-intersection-observer";

import card1Img from "./images/card1.svg";
import card2Img from "./images/card2.svg";
import card3Img from "./images/card3.svg";

const TextNumber = styled(Text)`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-evenly;

	@media (min-width: 700px) {
		&:after {
			content: "";
			display: ${({ disableLine }) => (disableLine ? "none" : "block")};
			background: #4a5060;
			width: 80%;
			height: 1px;
			float: right;
			position: absolute;
			right: -46%;
		}
	}

	@media (min-width: 1000px) {
		justify-content: space-between;

		&:after {
			position: relative;
			right: 0;
		}
	}
`;

const BoxAnimate = styled(Box)`
	transition: all 0.4s ease-out;

	&:nth-child(2) {
		transition-delay: 0.2s;
	}
	&:nth-child(3) {
		transition-delay: 0.4s;
	}
`;

const INITIAL = [
	{
		opacity: 0,
		transform: "translateX(-200px)",
	},
	{
		opacity: 0,
		transform: "translateX(-250px)",
	},
	{
		opacity: 0,
		transform: "translateX(-300px)",
	},
];

const ANIMATE = {
	opacity: 1,
	transform: "translateX(0px)",
};

const Steps = () => {
	const { site }: any = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					appUrl
				}
			}
		}
	`);

	const appUrl = site.siteMetadata?.appUrl || "/";

	const { ref, inView } = useInView({
		threshold: 0.05,
		triggerOnce: true,
	});

	return (
		<Box
			sx={{
				backgroundColor: "rgba(0,0,0,0.6)",
				position: "relative",
				zIndex: 2,
			}}
			textAlign={["center", "center", "left", "left"]}
		>
			<Box variant="gridcontainer" pt={["0px", "10px", "60px"]}>
				<Box
					sx={{
						gridColumn: [
							"span 6 !important",
							"span 6 !important",
							"span 4 !important",
							"span 4 !important",
						],
					}}
					pt="60px"
					pr={[0, 0, "30px", "60px"]}
				>
					<img src={card1Img} alt="" />
					<Text variant="leadsm" color="text.primaryalt" mt="28px">
						Design freedom
					</Text>
					<Text variant="bodymd" color="text.primary" mt="10px">
						Customize the hell out of your responsive grid and
						switch between absolute and auto-layout positioning on
						the fly.
					</Text>
				</Box>
				<Box
					sx={{
						gridColumn: [
							"span 6 !important",
							"span 6 !important",
							"span 4 !important",
							"span 4 !important",
						],
					}}
					pt="60px"
					pr={[0, 0, "30px", "60px"]}
				>
					<img src={card2Img} alt="" />
					<Text variant="leadsm" color="text.primaryalt" mt="28px">
						Familiar tools
					</Text>
					<Text variant="bodymd" color="text.primary" mt="10px">
						The comfort of graphic design tools and the power of
						modern frontend technologies – combined in one seamless
						experience.
					</Text>
				</Box>
				<Box
					sx={{
						gridColumn: [
							"span 6 !important",
							"4 / span 6 !important",
							"span 4 !important",
							"span 4 !important",
						],
					}}
					pt="60px"
					pr={[0, 0, "30px", "60px"]}
				>
					<img src={card3Img} alt="" />
					<Text variant="leadsm" color="text.primaryalt" mt="28px">
						Instant publishing
					</Text>
					<Text variant="bodymd" color="text.primary" mt="10px">
						What would it take to transform your most daring design
						idea into a live web-page? One click and a couple of
						seconds.
					</Text>
				</Box>
			</Box>
			<Box variant="gridcontainer" pt={["100px", "120px", "180px"]}>
				<Box
					sx={{
						gridColumn: [
							"span 6 !important",
							"3 / span 8 !important",
							"span 4 !important",
						],
					}}
					pr={[0, 0, "30px", "60px"]}
				>
					<Text
						variant="leadmd"
						color="text.primaryalt"
						mr={["0", "0", "40px"]}
					>
						Only 3 steps standing between you and your best web-page
						yet
					</Text>
				</Box>
				<Flex
					sx={{
						gridColumn: [
							"span 6 !important",
							"span 12 !important",
							"span 8 !important",
							"span 8 !important",
						],
						flexDirection: ["column", "row"],
						textAlign: ["center", "center", "left"],
					}}
					ref={ref}
				>
					<BoxAnimate
						pr={[0, "30px"]}
						mt={["30px", "30px", 0]}
						style={inView ? ANIMATE : INITIAL[0]}
					>
						<TextNumber variant="leadmd" color="text.primaryalt">
							1
						</TextNumber>
						<Text
							variant="bodylg"
							color="text.primary"
							mt="17px"
							mr={["0", "0", "60px"]}
						>
							Pick a template or start from scratch
						</Text>
					</BoxAnimate>
					<BoxAnimate
						pr={[0, "30px"]}
						mt={["30px", "30px", 0]}
						style={inView ? ANIMATE : INITIAL[1]}
					>
						<TextNumber variant="leadmd" color="text.primaryalt">
							2
						</TextNumber>
						<Text
							variant="bodylg"
							color="text.primary"
							mt="17px"
							mr={["0", "0", "60px"]}
						>
							Use familiar tools to design a web-page
						</Text>
					</BoxAnimate>
					<BoxAnimate
						mt={["30px", "30px", 0]}
						style={inView ? ANIMATE : INITIAL[2]}
					>
						<TextNumber
							variant="leadmd"
							color="text.primaryalt"
							disableLine
						>
							3
						</TextNumber>
						<Text variant="bodylg" color="text.primary" mt="17px">
							Publish your web-page in one click
						</Text>
					</BoxAnimate>
				</Flex>
			</Box>
			<Box variant="gridcontainer" pb={["115px", "100px", "150px"]}>
				<Box>
					<Button
						as="a"
						display="inline-flex"
						mt="60px"
						href={`${appUrl}signup`}
						variant="warning.rounded.lg"
						data-link="steps"
					>
						Get started
					</Button>
				</Box>
			</Box>
		</Box>
	);
};

export default React.memo(Steps);
