import React from "react";
import styled from "@emotion/styled";
import { Flex, Box, Text, Button } from "@graphite/uneon";
import { graphql, useStaticQuery } from "gatsby";
import { useInView } from "react-intersection-observer";

import desingIcons from "../../../images/desingIcons.svg";
import animationIcons from "../../../images/animationIcons.svg";
import componentsIcons from "../../../images/componentsIcons.svg";
import publishingIcons from "../../../images/publishingIcons.svg";

import gridMp4 from "./images/grid.mp4";
import gridWebm from "./images/grid.webm";
import animationMp4 from "./images/animation.mp4";
import animationWebm from "./images/animation.webm";
import componentsMp4 from "./images/components.mp4";
import componentsWebm from "./images/components.webm";
import publicationMp4 from "./images/publication.mp4";
import publicationWebm from "./images/publication.webm";

const textBox = {
	gridColumn: [
		"span 6 !important",
		"span 12 !important",
		"span 9 !important",
		"span 6 !important",
	],
	zIndex: 1,
};

const imageBox = {
	position: "relative",
	gridColumn: [
		"span 6 !important",
		"span 12 !important",
		"span 3 !important",
		"span 6 !important",
	],
	zIndex: 0,
};

const LinkText = styled(Text)<{
	theme: {
		colors: {
			text: {
				primaryalt: string;
				primary: string;
			};
		};
	};
	isActive: boolean;
	icon: string;
}>`
	color: ${({ theme }) => theme.colors.text.primaryalt};
	cursor: default;
	display: flex;
	flex-wrap: nowrap;
	white-space: nowrap;

	transition-property: transform;
	transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
	transition-duration: 0.7s;

	@media (min-width: 1000px) {
		color: ${({ theme, isActive }) =>
			isActive
				? theme.colors.text.primaryalt
				: theme.colors.text.primary};
		transform: ${({ isActive }) =>
			isActive ? "translateX(40px)" : "translateX(-62px)"};

		&:before {
			content: "";
			display: inline-block;
			width: 62px;
			height: 62px;
			background-image: url(${({ icon }) => icon});
			transform: ${({ isActive }) =>
				isActive ? "translateX(-40px)" : "translateX(-102px)"};
			transition-property: transform;
			transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
			transition-duration: 0.9s;
			vertical-align: text-top;
		}
	}
`;

const LinkWrapper = styled(Flex)`
	flex-direction: column;
	position: relative;
	align-items: flex-start;

	&:after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 80px;
		background: linear-gradient(
			90deg,
			${({ theme }: { theme: any }) => theme.colors.bg.primaryminus} 0%,
			#02020300 100%
		);
	}
`;

const VideoWrapper = styled(Box)<{
	theme: {
		colors: {
			bg: {
				primaryplus: string;
			};
		};
	};
	isActive: boolean;
}>`
	position: sticky;
	top: 0;

	transition: opacity 1s ease-out;
	opacity: ${({ isActive }) => (isActive ? 1 : 0)};
	height: ${({ isActive }) => (isActive ? "auto" : 0)};
	overflow: hidden;

	video {
		width: 100%;
		border: 2px solid ${({ theme }) => theme.colors.bg.primaryplus};
		border-radius: 6px;
		box-sizing: border-box;
	}
`;

const links = [
	{
		name: "Design system",
		icon: desingIcons,
		text: "Create custom presets and introduce changes across multiple projects at any scale necessary.",
		webm: gridWebm,
		mp4: gridMp4,
	},
	{
		name: "Animation",
		icon: animationIcons,
		text: "Breathe some life into your web-page with stylish, professional-grade animations.",
		webm: animationWebm,
		mp4: animationMp4,
	},
	{
		name: "Components",
		icon: componentsIcons,
		text: "Pack your brightest ideas in custom components for future reuse and improvement.",
		webm: componentsWebm,
		mp4: componentsMp4,
	},
	{
		name: "Publishing",
		icon: publishingIcons,
		text: "Instantly launch your design ideas into the Web – all it costs is one click.",
		webm: publicationWebm,
		mp4: publicationMp4,
	},
];

const Features = () => {
	const {
		site,
		videoPoster1,
		videoPoster2,
		videoPoster3,
		videoPoster4,
	}: any = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					appUrl
				}
			}
			videoPoster1: file(relativePath: { eq: "video-poster-1.jpg" }) {
				...videoImage
			}
			videoPoster2: file(relativePath: { eq: "video-poster-2.jpg" }) {
				...videoImage
			}
			videoPoster3: file(relativePath: { eq: "video-poster-3.jpg" }) {
				...videoImage
			}
			videoPoster4: file(relativePath: { eq: "video-poster-4.jpg" }) {
				...videoImage
			}
		}
	`);

	const videos = [
		videoPoster1.childImageSharp.fluid.base64,
		videoPoster2.childImageSharp.fluid.base64,
		videoPoster3.childImageSharp.fluid.base64,
		videoPoster4.childImageSharp.fluid.base64,
	];

	const appUrl = site.siteMetadata?.appUrl || "/";

	const [active, setActive] = React.useState(0);

	const { ref, inView } = useInView({
		triggerOnce: true,
	});

	return (
		<Box
			variant="gridcontainer"
			pt={["75px", "80px", "121px"]}
			overflow="hidden"
			ref={ref}
		>
			<Box sx={textBox} pb={[0, 0, "200px", "200px"]}>
				<Text variant="sectionmd" color="text.accent">
					Features
				</Text>
				<LinkWrapper m="36px -80px 0" p="0 80px" overflow="hidden">
					{links.map((link, i) => (
						<React.Fragment key={link.name}>
							<LinkText
								mt="25px"
								variant="hero2"
								icon={link.icon}
								isActive={active === i}
								onMouseEnter={() => setActive(i)}
								onClick={() => setActive(i)}
							>
								{link.name}
							</LinkText>
							<VideoWrapper
								isActive
								display={[
									inView ? "block" : "none",
									inView ? "block" : "none",
									"none",
								]}
								mt="20px"
							>
								<video
									autoPlay
									loop
									muted
									playsInline
									poster={videos[i]}
								>
									{inView && (
										<>
											<source
												src={link.webm}
												type="video/webm"
											/>
											<source
												src={link.mp4}
												type="video/mp4"
											/>
										</>
									)}
								</video>
								{i === 2 && (
									<Text
										variant="sectionmd"
										color="#FAFCFD"
										sx={{
											display: "inline-block",
											padding: "3px 9px",
											background: "#4870FF",
											borderRadius: "99px",
										}}
										mt="24px"
									>
										Coming soon
									</Text>
								)}
								<Text
									variant="bodylg"
									color="text.secondary"
									mt="24px"
									mb="24px"
								>
									{link.text}
								</Text>
							</VideoWrapper>
						</React.Fragment>
					))}
				</LinkWrapper>

				<Button
					as="a"
					display="inline-flex"
					mt={["58px", "58px", "124px"]}
					href={`${appUrl}signup`}
					variant="warning.rounded.lg"
					data-link="feature"
				>
					Try it today
				</Button>
			</Box>
			<Box sx={imageBox} display={["none", "none", "block"]}>
				{links.map((link, i) => (
					<VideoWrapper
						key={link.name}
						isActive={active === i}
						width={["100%", "100%", "250%", "117%"]}
						ml={[0, 0, "-150%", "-17%"]}
						mt={active === i ? [0, "85px", `${45 * i + 30}px`] : 0}
					>
						<video
							autoPlay
							loop
							muted
							playsInline
							poster={videos[i]}
						>
							{inView && (
								<>
									<source src={link.webm} type="video/webm" />
									<source src={link.mp4} type="video/mp4" />
								</>
							)}
						</video>
						{i === 2 && (
							<Text
								variant="sectionmd"
								color="#FAFCFD"
								sx={{
									display: "inline-block",
									padding: "3px 9px",
									background: "#4870FF",
									borderRadius: "99px",
								}}
								mt="17px"
							>
								Coming soon
							</Text>
						)}
						<Text
							variant="bodylg"
							color="text.secondary"
							mt="17px"
							mb="24px"
						>
							{link.text}
						</Text>
					</VideoWrapper>
				))}
			</Box>
		</Box>
	);
};

export default React.memo(Features);
