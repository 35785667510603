import React from "react";
import { PageProps, useStaticQuery, graphql } from "gatsby";
import styled from "@emotion/styled";
import { ThemeProvider } from "emotion-theming";
import Img from "gatsby-image";

import { Box } from "@graphite/uneon";
import themes from "../components/v2/libs/themes";
import Layout from "../components/v2/Layout";
import SEO from "../components/v2/SEO";
import Hero from "../components/v2/Hero";
import Steps from "../components/v2/Steps";
import TopFeature from "../components/v2/TopFeature";
import Features from "../components/v2/Features";
import Ecosystem from "../components/v2/Ecosystem";
import Roadmap from "../components/v2/Roadmap";
import YouWillLoveIt from "../components/v2/YouWillLoveIt";
import Try from "../components/v2/Try";
import Footer from "../components/v2/Footer";

declare global {
	interface Window {
		amplitude: any;
	}
}

const BackgroundImg = styled(Img)`
	position: absolute !important;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 1;
`;

const IndexPage: React.FC<PageProps<{}>> = () => {
	const { bgoverview }: any = useStaticQuery(graphql`
		query {
			bgoverview: file(relativePath: { eq: "bgoverview.png" }) {
				...videoImage
			}
		}
	`);

	return (
		<Layout>
			<SEO />

			<Box
				sx={{
					position: "relative",
					overflow: "hidden",
				}}
			>
				<BackgroundImg
					fluid={{
						...bgoverview.childImageSharp.fluid,
						media: "(max-width: 480px)",
					}}
				/>
				<Hero />
				<Steps />
			</Box>

			<ThemeProvider theme={themes.light}>
				<Box bg="bg.primaryalt">
					<TopFeature />
				</Box>
			</ThemeProvider>

			<Box bg="bg.primaryminus">
				<Features />
				<Ecosystem />
			</Box>
			<ThemeProvider theme={themes.light}>
				<Box bg="bg.primaryalt">
					<Roadmap />
				</Box>
			</ThemeProvider>
			<Box
				sx={{
					backgroundColor: "bg.primaryminus",
					position: "relative",
					"&:after": {
						content: '""',
						position: "absolute",
						top: 0,
						width: "100%",
						height: "420px",
						background:
							"linear-gradient(180deg, #191925 2.8%, rgba(25, 25, 37, 0) 100%)",
						zIndex: -1,
					},
				}}
			>
				<YouWillLoveIt />
				<Try />
				<Footer />
			</Box>
		</Layout>
	);
};

export default IndexPage;
