import React from "react";
import {
	m,
	useTransform,
	useViewportScroll,
	LazyMotion,
	domAnimation,
} from "framer-motion";
import { useInView } from "react-intersection-observer";
import styled from "@emotion/styled";
import { css } from "@emotion/core";
import { graphql, useStaticQuery } from "gatsby";
import { Flex, Box } from "@graphite/uneon";
import Img from "gatsby-image";

import figureImage from "./images/figure.svg";
import shapeImage from "./images/shape.svg";
import Triangle from "./images/triangle.inline.svg";

type Tprops = {
	readonly showVideo: (arg: boolean) => void;
};

const radiusCss = css`
	border-radius: 4px;
	height: auto;
	width: auto;

	@media screen and (min-width: 700px) {
		border-radius: 5px;
	}

	@media screen and (min-width: 1000px) {
		border-radius: 6px;
	}

	@media screen and (min-width: 1400px) {
		border-radius: 8px;
	}
`;

const EditorMotion = styled(m.div)`
	width: 90vw;
	position: absolute;
	max-width: 1080px;

	@media (min-width: 700px) {
		width: 87vw;
	}

	@media (min-width: 1000px) {
		width: 56vw;
	}
`;

const EditorImg = styled(Img)`
	box-shadow: rgb(0 0 0 / 15%) 0px 30px 60px 0px,
		rgb(0 0 0 / 5%) 0px 20px 80px 20px;

	${radiusCss}
`;

const LeftImgMotion = styled(m.div)`
	width: 20vw;
	max-width: 212px;
	height: auto;
	left: -5vw;
	position: absolute;
	top: 50%;
	margin-top: -20%;

	@media (min-width: 700px) {
		left: -2%;
		width: 18vw;
		margin-top: -20%;
	}
	@media (min-width: 1000px) {
		left: -59%;
		width: 11vw;
		margin-top: -46%;
	}
`;

const LeftImg = styled(Img)`
	filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.1));
	${radiusCss}
`;

const RightImgMotion = styled(m.div)`
	width: 20vw;
	height: auto;
	max-width: 212px;
	position: absolute;
	right: -8vw;
	top: 50%;
	margin-top: -20%;

	@media (min-width: 700px) {
		right: -2%;
		width: 17.5vw;
		margin-top: -20%;
	}

	@media (min-width: 1000px) {
		right: -35%;
		width: 11vw;
		height: auto;
		margin-top: -46%;
	}

	// для разрешений больше чем fullHD
	@media (min-width: 1921px) {
		right: -58%;
		width: 11vw;
		height: auto;
		margin-top: -46%;
	}
`;

const RightImg = styled(Img)`
	filter: drop-shadow(0px 20px 40px rgba(0, 0, 0, 0.1));
	${radiusCss}
`;

const BoxShadow = styled(Box)`
	position: absolute;

	width: 58px;
	height: 58px;
	border-radius: 66px;
	border: none;
	background-color: #000;
	opacity: 0.6;

	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	box-shadow: 0px 60px 86px 66px rgba(0, 0, 0, 1);

	@media screen and (min-width: 700px) {
		width: 66px;
		height: 66px;
		border-radius: 75px;

		box-shadow: 0px 68px 95px 75px rgba(0, 0, 0, 1);
	}

	@media screen and (min-width: 1000px) {
		width: 72px;
		height: 72px;
		border-radius: 80px;

		box-shadow: 0px 74px 102px 82px rgba(0, 0, 0, 1);
	}

	@media screen and (min-width: 1400px) {
		width: 88px;
		height: 88px;
		border-radius: 100px;

		box-shadow: 0px 90px 120px 100px rgba(0, 0, 0, 1);
	}

	// Вроде как это должно быть для full hd, но с такими BP некуда пихать(
	// @media screen and (min-width: 1920px) {
	// 	width: 106px;
	// 	height: 106px;
	// 	border-radius: 120px;

	// 	box-shadow: 0px 108px 140px 120px rgba(0, 0, 0, 1);
	// }
`;

const variantsEditor = {
	from: {
		y: "-30%",
	},
	to: {
		y: "0%",
	},
};

const variantsImg = {
	from: {
		y: "-50%",
	},
	to: {
		y: "0%",
	},
};

const Editor = ({ showVideo }: Tprops) => {
	const { editorDesktop, editorMobile, image35, image33 }: any =
		useStaticQuery(
			graphql`
				query {
					editorDesktop: file(
						relativePath: { eq: "editor-first.jpg" }
					) {
						childImageSharp {
							fluid(maxWidth: 1140, quality: 95) {
								...GatsbyImageSharpFluid_withWebp
								presentationHeight
								presentationWidth
							}
						}
					}
					editorMobile: file(
						relativePath: { eq: "editor-first.jpg" }
					) {
						childImageSharp {
							fluid(maxWidth: 600, quality: 95) {
								...GatsbyImageSharpFluid_withWebp
								presentationHeight
								presentationWidth
							}
						}
					}
					image35: file(relativePath: { eq: "image35.png" }) {
						...videoImage
					}
					image33: file(relativePath: { eq: "image33.png" }) {
						...videoImage
					}
				}
			`
		);

	const distance = React.useMemo(() => {
		if (
			typeof window !== "undefined" &&
			window?.matchMedia(`(max-width: 1000px)`).matches
		) {
			return [0, 0.2];
		}

		return [0, 0.1];
	}, []);

	const { scrollYProgress } = useViewportScroll();
	const yEditor = useTransform(scrollYProgress, distance, ["0%", "-10%"]);
	const yLeft = useTransform(scrollYProgress, distance, ["0%", "-40%"]);
	const yRight = useTransform(scrollYProgress, distance, ["0%", "-60%"]);

	const [ref, inView] = useInView({
		threshold: 0.2, // добавить в настройки
		triggerOnce: true, // срабатывает только один раз
	});

	return (
		<LazyMotion features={domAnimation}>
			<Flex
				sx={{
					gridColumn: [
						"span 6 !important",
						"span 12 !important",
						"span 4 !important",
						"span 4 !important",
					],
					gridColumnEnd: [
						"none",
						"none",
						"13 !important",
						"13 !important",
					],
					alignItems: "center",
					justifyContent: "center",
					position: "relative",
					marginTop: ["40px", "60px", 0],
					marginBottom: ["55px", 0],
				}}
			>
				<Box
					as="img"
					src={figureImage}
					alt=""
					sx={{
						aspectRatio: "1/1.135",
						objectFit: "contain",
						objectPosition: "50% 50%",
						width: ["70vw", "62vw", "41vw"],
						height: "auto",
						position: [
							"relative",
							"relative",
							"absolute !important",
							"absolute !important",
						],
						maxWidth: "758px",
					}}
				/>
				<EditorMotion
					ref={ref}
					variants={variantsEditor}
					initial="from"
					transition={{
						delay: 0.2,
						duration: 0.35,
					}}
					animate={inView ? "to" : "from"}
				>
					<m.div
						style={{
							y: yEditor,
						}}
					>
						<EditorImg
							fluid={[
								{
									...editorMobile.childImageSharp.fluid,
									media: "(max-width: 699px)",
								},
								{
									...editorDesktop.childImageSharp.fluid,
									media: `(min-width: 700px)`,
								},
							]}
						/>
					</m.div>
				</EditorMotion>
				<Flex
					sx={{
						position: "absolute",
						width: "80px",
						height: "80px",

						"@media (min-width: 700px)": {
							width: "91px",
							height: "91px",
						},
						"@media (min-width: 1000px)": {
							width: "98px",
							height: "98px",
						},
						"@media (min-width: 1400px)": {
							width: "120px",
							height: "120px",
						},
						// мне жалко удалять это, так нарисовано в фигме(
						// "@media (min-width: 1920px)": {
						// 	width: "145px",
						// 	height: "145px"
						// }
					}}
					onClick={() => showVideo(true)}
				>
					<BoxShadow />
					<Flex
						sx={{
							backgroundSize: "cover",
							backgroundImage: `url(${shapeImage})`,
							width: "100%",
							height: "100%",
							cursor: "pointer",
							transition: "transform 0.25s linear",
							alignItems: "center",
							justifyContent: "center",
							zIndex: 2,

							"@media (min-width: 700px)": {
								transform: "scale(1)",
								"&:hover": {
									transform: "scale(1.15)",
								},
							},
						}}
					>
						<Triangle style={{ marginLeft: "10px" }} />
					</Flex>
				</Flex>
				<LeftImgMotion
					variants={variantsImg}
					initial="from"
					transition={{
						delay: 0.3,
						duration: 0.5,
					}}
					animate={inView ? "to" : "from"}
				>
					<m.div
						style={{
							y: yLeft,
						}}
					>
						<LeftImg
							fluid={{
								...image35.childImageSharp.fluid,
								media: "(min-width: 0)",
							}}
						/>
					</m.div>
				</LeftImgMotion>
				<RightImgMotion
					variants={variantsImg}
					initial="from"
					transition={{
						delay: 0.4,
						duration: 0.5,
					}}
					animate={inView ? "to" : "from"}
				>
					<m.div
						style={{
							y: yRight,
						}}
					>
						<RightImg
							fluid={{
								...image33.childImageSharp.fluid,
								media: "(min-width: 0)",
							}}
						/>
					</m.div>
				</RightImgMotion>
			</Flex>
		</LazyMotion>
	);
};

export default React.memo(Editor);
