import React from "react";
import styled from "@emotion/styled";
import { Box, Text, Button } from "@graphite/uneon";
import { graphql, useStaticQuery } from "gatsby";
import { useInView } from "react-intersection-observer";

import card1Img from "./images/card1.svg";
import card2Img from "./images/card2.svg";
import card3Img from "./images/card3.svg";
import gridMp4 from "./images/grid.mp4";
import gridWebm from "./images/grid.webm";

const EditorImg = styled(Box)`
	grid-column-start: 7;
	video {
		width: 100%;
		border-radius: 8px;
	}
`;

const Card = styled(Box)``;

const cardSx = {
	margin: ["0px 0px 12px", "0"],
	height: ["auto", "auto", "auto", "fit-content"],
	gridColumn: [
		"span 6 !important",
		"span 4 !important",
		"span 2 !important",
		"span 2 !important",
	],
	borderRadius: [0, "6px"],
	boxShadow: [
		"none",
		"rgb(0 0 0 / 3%) 0px 14px 36px, rgb(0 0 0 / 2%) 0px 30px 60px",
	],
	padding: [0, "15px 15px 12px"],
	background: ["inherit", "#fff"],
	// height: "fit-content"
};

const TopFeature = () => {
	const { site, hydridGrid }: any = useStaticQuery(graphql`
		query {
			site {
				siteMetadata {
					appUrl
				}
			}
			hydridGrid: file(relativePath: { eq: "hydrid-grid.jpg" }) {
				...videoImage
			}
		}
	`);

	const appUrl = site.siteMetadata?.appUrl || "/";

	const { ref, inView, entry } = useInView({
		triggerOnce: true,
	});

	React.useEffect(() => {
		if (inView) (entry?.target as HTMLVideoElement).load();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [inView]);

	return (
		<Box
			variant="gridcontainer"
			pt={["70px", "70px", "120px"]}
			pb={["90px", "150px"]}
			sx={{
				gridGap: "24px 30px",
				gridTemplateAreas: [
					`
				"subtitle subtitle subtitle subtitle subtitle subtitle"
				"title title title title title title"
				"text text text text text text"
				"card1 card1 card1 card1 card1 card1"
				"card2 card2 card2 card2 card2 card2"
				"card3 card3 card3 card3 card3 card3"
				"button button button button button button"
				"image image image image image image"
				`,
					`
				"subtitle subtitle subtitle subtitle subtitle subtitle subtitle subtitle subtitle subtitle subtitle subtitle"
				"title title title title title title title title title title title title"
				"text text text text text text text text text text text text"
				"card1 card1 card1 card1 card2 card2 card2 card2 card3 card3 card3 card3"
				"button button button button button button button button button button button button"
				"image image image image image image image image image image image image"
				`,
					`
				"subtitle subtitle subtitle subtitle subtitle subtitle subtitle subtitle subtitle subtitle subtitle subtitle"
				"title title title title title title title title title . . ."
				"text text text text . image image image image image image image"
				"card1 card1 card2 card2 . image image image image image image image"
				"card3 card3 . . . image image image image image image image"
				"button button button button button button button button button button button button"
				`,
					`
				"subtitle subtitle subtitle subtitle subtitle subtitle subtitle subtitle subtitle subtitle subtitle subtitle"
				"title title title title title title title title title . . ."
				"text text text text image image image image image image image image"
				"card1 card1 card2 card2 image image image image image image image image"
				"card3 card3 . . image image image image image image image image"
				"button button . . image image image image image image image image"
				`,
				],
				gridTemplateRows:
					"min-content min-content min-content min-content 1fr",
			}}
		>
			<Text
				variant="sectionmd"
				color="text.accent"
				sx={{
					gridArea: "subtitle",
					gridColumn: ["span 6 !important", "span 12 !important"],
				}}
			>
				Top feature
			</Text>

			<Text
				variant="hero1"
				color="text.primaryalt"
				sx={{
					gridArea: "title",
					gridColumn: [
						"span 6 !important",
						"span 12 !important",
						"span 12 !important",
						"span 10 !important",
					],
				}}
				mt={["14px", "14px", "6px"]}
				mb={["35px", "35px", "110px"]}
			>
				The ultimate web design experience
			</Text>

			<Box
				sx={{
					gridArea: "text",
					gridColumn: [
						"span 6 !important",
						"span 12 !important",
						"span 4 !important",
						"span 4 !important",
					],
				}}
				mb="18px"
			>
				<Text variant="hero3" color="text.primaryalt">
					Hybrid grid
				</Text>
				<Text variant="bodylg" color="text.primary" mt="26px">
					Get the best out of both worlds: switch between absolute and
					grid-based positioning on the fly, or combine them in one
					workspace with the extremely versatile Hybrid Grid
					technology.
				</Text>
			</Box>
			<Card sx={{ ...cardSx, gridArea: "card1" }}>
				<img src={card1Img} alt="" />
				<Text variant="headlinesm" color="text.primary" mt="10px">
					Fully customizable
				</Text>
			</Card>
			<Card sx={{ ...cardSx, gridArea: "card2" }}>
				<img src={card2Img} alt="" />
				<Text variant="headlinesm" color="text.primary" mt="10px">
					Precise and consistent
				</Text>
			</Card>
			<Card sx={{ ...cardSx, gridArea: "card3" }}>
				<img src={card3Img} alt="" />
				<Text variant="headlinesm" color="text.primary" mt="10px">
					Responsive by design
				</Text>
			</Card>
			<EditorImg
				sx={{
					gridArea: "image",
					gridColumn: [
						"span 6 !important",
						"span 12 !important",
						"span 7 !important",
						"span 7 !important",
					],
					gridColumnEnd: [
						"auto",
						"auto",
						"13 !important",
						"13 !important",
					],
					position: "relative",
				}}
				mt={["76px", "47px", 0]}
			>
				<video
					autoPlay
					loop
					muted
					playsInline
					ref={ref}
					poster={hydridGrid.childImageSharp.fluid.base64}
				>
					{inView && (
						<>
							<source src={gridWebm} type="video/webm" />
							<source src={gridMp4} type="video/mp4" />
						</>
					)}
				</video>
			</EditorImg>
			<Box
				sx={{
					gridArea: "button",
				}}
			>
				<Button
					as="a"
					display="inline-flex"
					mt={["15px", "24px", "76px"]}
					href={`${appUrl}signup`}
					variant="warning.rounded.lg"
					data-link="topfeature"
				>
					Try it today
				</Button>
			</Box>
		</Box>
	);
};

export default React.memo(TopFeature);
