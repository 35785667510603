import React from "react";
import styled from "@emotion/styled";
import { graphql, useStaticQuery } from "gatsby";
import { Flex, Box, Text, Button } from "@graphite/uneon";

import TopBar from "./TopBar";
import Editor from "./Editor";

export const videoImage = graphql`
	fragment videoImage on File {
		childImageSharp {
			fluid(maxWidth: 1140, quality: 95) {
				...GatsbyImageSharpFluid_withWebp
				presentationHeight
				presentationWidth
			}
		}
	}
`;

const wrapperSx = {
	display: "grid",
	position: "relative",
	paddingBottom: ["0", "80px", "0"],
	justifyItems: "center",
	overflow: "visible",
	textAlign: ["center", "center", "left"],
	zIndex: 3,
};

const Header = styled(Text)`
	-webkit-background-clip: text !important;
	-webkit-text-fill-color: transparent;
	background: linear-gradient(
		287.43deg,
		rgb(119, 77, 255) 33.27%,
		rgb(35, 158, 185) 61.72%,
		rgb(215, 62, 135) 82.93%
	);
	text-transform: uppercase;
`;

const Video = styled(Flex)`
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background: rgba(0, 0, 0, 0.9);
	justify-content: center;
	align-items: center;
	z-index: 9999;
`;

const VideoInner = styled.div`
	position: relative;

	height: 0;
	padding-top: 56.2%;
	width: calc(100vw - 60px);
	z-index: 1;

	> iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	@media (min-width: 700px) {
		height: calc(100vh - 180px);
		width: calc(100vw - 180px);
		padding-top: 0;

		> iframe {
			width: 100%;
			height: 100%;
		}
	}
`;

const Cross = styled(Box)`
	position: absolute;
	width: 24px;
	height: 24px;
	cursor: pointer;
	top: 24px;
	right: 24px;
	z-index: 3;

	&:after,
	&:before {
		content: "";
		position: absolute;
		height: 1px;
		width: 24px;
		background: #fff;
		transform: rotate(45deg);
	}

	&:before {
		transform: rotate(-45deg);
	}
`;

const Content = () => {
	const { site }: any = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						appUrl
					}
				}
			}
		`
	);

	const appUrl = site.siteMetadata?.appUrl || "/";

	const [isShowVideo, showVideo] = React.useState(false);

	return (
		<>
			<Box sx={wrapperSx} data-kind="hero">
				<Box
					variant="gridcontainer"
					sx={{
						maxWidth: "1920px",
						zIndex: 3,
					}}
				>
					<TopBar />
				</Box>
				<Box
					variant="gridcontainer"
					sx={{
						maxWidth: "1920px",
						minHeight: [
							"auto",
							"auto",
							"calc(min(100vh, 1080px) - 114px)",
						],
						maxHeight: ["none", "none", "1080px"],
						zIndex: 2,
					}}
				>
					<Box
						sx={{
							gridColumn: [
								"span 6 !important",
								"2 / span 10 !important",
								"span 5 !important",
								"span 4 !important",
							],
							display: "grid",
							alignContent: "center",
							padding: ["40px 0 0", "30px 0 30px", "50px 0 80px"],
							zIndex: 2,
						}}
					>
						<Header
							variant="sectionmd"
							color="text.accent"
							textTransform="uppercase"
							fontSize={["13px", "15px"]}
						>
							Responsive. Pixel-Perfect. Code-Free.
						</Header>
						<Text variant="hero2" color="text.primaryalt" mt="30px">
							Make web design feel like home
						</Text>
						<Text variant="bodylg" color="text.primary" mt="12px">
							Use familiar graphic editor tools to create
							pixel-perfect Web Pages, and not just mockups. Focus
							on the creative part, and we&apos;ll take care of
							the rest.
						</Text>
						<Box mt={["30px", "26px"]}>
							<a
								href="https://www.producthunt.com/posts/graphite-5?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-graphite-5"
								target="_blank"
								rel="noreferrer"
							>
								<img
									src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=296431&theme=dark&period=monthly"
									alt="Graphite - Create live websites just like in a graphic editor | Product Hunt"
									style={{ width: "250px", height: "54px" }}
									width="250"
									height="54"
								/>
							</a>
						</Box>
						<Box mt="40px">
							<Button
								as="a"
								display="inline-flex"
								href={`${appUrl}signup`}
								variant="warning.rounded.lg"
							>
								Try for free
							</Button>
							<Button
								as="a"
								display="inline-flex"
								ml="20px"
								onClick={() => showVideo(true)}
								variant="primaryghost.rounded.lg"
							>
								Watch video
							</Button>
						</Box>
					</Box>
					<Editor showVideo={showVideo} />
				</Box>
			</Box>

			{isShowVideo && (
				<Video onClick={() => showVideo(false)}>
					<Cross />
					<VideoInner>
						<iframe
							width="100%"
							height="1200"
							src="https://www.youtube.com/embed/FbmGnSNKccg?autoplay=1&hd=1&vq=hd1080&modestbranding=1&autohide=1&showinfo=0&controls=0"
							title="YouTube video player"
							frameBorder="0"
							allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
							allowFullScreen
						/>
					</VideoInner>
				</Video>
			)}
		</>
	);
};

export default React.memo(Content);
