import React from "react";
import { Flex, Box, Text } from "@graphite/uneon";

import Slide from "./Slide";
import Shape from "./Shape";

import GlyphSvg from "./images/glyph.inline.svg";

const colText = {
	gridColumn: [
		"span 6 !important",
		"span 8 !important",
		"span 12 !important",
		"span 6 !important",
	],
};

const colBtn = {
	gridColumn: [
		"span 6 !important",
		"span 4 !important",
		"span 12 !important",
		"span 6 !important",
	],
};

const SLIDE_WIDTH = 180 + 90;

const rowSx = {
	width: "100vw", // Fluid
	position: "relative",
	justifyContent: "center",
	marginTop: "28px",

	"&:before": {
		content: '""',
		position: "absolute",
		background:
			"linear-gradient(270deg, #ECF2F5 0%, rgba(236, 242, 245, 0) 94.27%)",
		opacity: "0.75",
		width: "30px",
		left: 0,
		height: "100%",
		zIndex: 2,
		transform: "matrix(-1, 0, 0, 1, 0, 0)",
	},

	"&:after": {
		content: '""',
		position: "absolute",
		background:
			"linear-gradient(270deg, #ECF2F5 0%, rgba(236, 242, 245, 0) 94.27%)",
		opacity: "0.75",
		width: "30px",
		right: 0,
		height: "100%",
		zIndex: 2,
	},

	"@media (min-width: 700px)": {
		"&:before": {
			width: "90px",
		},
		"&:after": {
			width: "90px",
		},
	},
};

const colSx = {
	position: "relative",
	width: "100%",

	overflowX: "scroll",
	overflowY: "visible",

	"::-webkit-scrollbar": {
		display: "none",
	},
	msOverflowStyle: "none" /* IE and Edge */,

	paddingBottom: "10px",
};

const contentSx = {
	paddingLeft: [
		"30px",
		"30px",
		"45px",
		"calc(90px - min(calc((1780px - 100vw)/2),0px))",
	],
	minWidth: "auto",

	transition: "transform 0.25s ease-out",
	willChange: "transform",
	transformStyle: "preserve-3d",

	"&:before": {
		content: '""',
		position: "absolute",
		bottom: "-1px",
		left: [
			"calc(min(calc(1780px - 100vh), 0px) - 60px)",
			"calc(min(calc(1780px - 100vh), 0px) - 60px)",
			"calc(min(calc(1780px - 100vh), 0px) - 90px)",
			"calc(min(calc(1780px - 100vh), 0px) - 180px)",
		],
		width: [
			"calc(100% + 60px)",
			"calc(100% + 60px)",
			"calc(100% + 90px)",
			"calc(100% + 180px)",
		],
		height: "1px",
		background: "linear-gradient(109.7deg, #D2DCE2 13.18%, #4870FF 86.82%)",
	},
};

const slides = [
	{
		isDone: true,
		header: "Сustom domains",
		text: "Custom domains for your published web-sites",
	},
	{
		isDone: true,
		header: "Resize options",
		text: "Width and height for adaptive resizing",
	},
	{
		isDone: true,
		header: "Rectangle",
		text: "Square and round shapes for web-page layout",
	},
	{
		isDone: true,
		header: "Self-align",
		text: "Align every element within a container separately",
	},
	{
		isDone: true,
		header: "Animation",
		text: "Animate your web-page elements",
	},
	{
		isDone: true,
		header: "Templates",
		text: "Ready-to-go templates to give you a headstart",
	},
	{
		isDone: true,
		header: "Forms",
		text: "Collect and process data that customers submit via your web-site",
	},
	{
		header: "Section library",
		text: "Assemble web-pages in a flash using pre-made sections",
	},
	{
		header: "Animation improvements",
		text: "Cross-element interaction and new animation options",
	},
	{
		header: "Performance improvements",
		text: "An even faster and stronger version of the editor ",
	},
	{
		header: "Tutorials",
		text: "A vast supply of learning materials to get you started with web design and our editor in no time",
	},
	{
		header: "Visual components",
		text: "Create reusable components made of one or multiple elements",
	},
	{
		header: "Headless CMS",
		text: "Integrate a headless CMS and enjoy full control of your web-site’s data flow",
	},
	{
		header: "Links",
		text: "A neat interface for adding various links",
	},
	{
		header: "Dashboard update",
		text: "Improved tools for sorting and managing your projects, as well as some extra options",
	},
];

const Content = () => {
	const contentRef = React.useRef<HTMLElement>();

	const handleScrollLeft = React.useCallback(() => {
		if (contentRef.current) {
			const scrollOptions: ScrollToOptions = {
				left: contentRef.current.scrollLeft - SLIDE_WIDTH,
				top: 0,
				behavior: "smooth",
			};
			contentRef.current.scrollTo(scrollOptions);
		}
	}, []);

	const handleScrollRight = React.useCallback(() => {
		if (contentRef.current) {
			const scrollOptions: ScrollToOptions = {
				left: contentRef.current.scrollLeft + SLIDE_WIDTH,
				top: 0,
				behavior: "smooth",
			};
			contentRef.current.scrollTo(scrollOptions);
		}
	}, []);

	React.useEffect(() => {
		if (contentRef.current) {
			const scrollOptions: ScrollToOptions = {
				left:
					contentRef.current.scrollLeft +
					slides.filter(({ isDone }) => isDone).length * SLIDE_WIDTH,
				top: 0,
				behavior: "smooth",
			};
			contentRef.current.scrollTo(scrollOptions);
		}
	}, []);

	const [showLeft, setShowLeft] = React.useState(false);
	const [showRight, setShowRight] = React.useState(true);
	const handleScroll = React.useCallback((e) => {
		const { scrollLeft, offsetWidth, scrollWidth } = e.target;

		setShowLeft(scrollLeft > 0);
		setShowRight(scrollLeft + offsetWidth < scrollWidth);
	}, []);

	const [trueColSx, setColsSx] = React.useState(colSx);
	React.useEffect(() => {
		setColsSx((sx) => ({ ...sx, scrollbarWidth: "none" }));
	}, []);

	return (
		<>
			<Box variant="gridcontainer">
				{/* Header */}
				<Text
					variant="sectionmd"
					color="text.accent"
					mt={["50px", "120px", "120px"]}
					mb={["40px", "37px"]}
				>
					Upcoming features
				</Text>
				<Box sx={colText}>
					<Text variant="hero3" color="text.primaryalt">
						Roadmap
					</Text>
				</Box>
				<Flex
					sx={colBtn}
					justifyContent={[
						"left",
						"flex-end",
						"flex-end",
						"flex-end",
					]}
					alignItems="center"
					mt={["50px", 0]}
				>
					<Shape
						isShow={showLeft}
						mr="10px"
						rotateSvg
						onClick={handleScrollLeft}
					>
						<GlyphSvg />
					</Shape>
					<Shape isShow={showRight} onClick={handleScrollRight}>
						<GlyphSvg />
					</Shape>
				</Flex>
			</Box>
			<Flex sx={rowSx} pb={["20px", "140px"]}>
				<Flex sx={trueColSx} ref={contentRef} onScroll={handleScroll}>
					<Flex sx={contentSx}>
						{slides.map(({ header, text, isDone = false }) => (
							<Slide
								key={header}
								heading={header}
								text={text}
								isDone={isDone}
							/>
						))}
					</Flex>
				</Flex>
			</Flex>
		</>
	);
};

export default React.memo(Content);
