import React from "react";
import styled from "@emotion/styled";
import { Flex, Box, Text } from "@graphite/uneon";

const Wrapper = styled(Box)`
	width: 180px;
	min-height: 190px;
	margin-right: 90px;
	position: relative;

	&:after {
		content: "";
		position: absolute;
		bottom: -5px;
		left: -5px;
		width: 10px;
		height: 10px;
		background: ${({ isDone }) => (isDone ? "#14CC86" : "#4870FF")};
		border-radius: 3px;
		transform: rotate(45deg);
	}
`;

const TextDone = styled(Text)`
	letter-spacing: 0.15em;
	text-transform: uppercase;
	background: #14cc86;
	border-radius: 99px;
	padding: 3px 9px;
	align-self: flex-start;
	visibility: ${({ isDone }) => (isDone ? "visible" : "hidden")};
`;

const slideContainerSx = {
	position: "relative",
	flexDirection: "column",
	borderRadius: "12px",
	overflow: "hidden",
	height: "100%",
};

const textSx = {
	marginTop: "15px",
	flex: 1,
};

interface TProps {
	readonly heading: string;
	readonly text: string;
	readonly isDone: boolean;
}

const Slide = ({ heading, text, isDone }: TProps) => (
	<Wrapper isDone={isDone}>
		<Flex sx={slideContainerSx}>
			<TextDone variant="sectionsm" color="text.oncolor" isDone={isDone}>
				Done
			</TextDone>

			<Text variant="headlinemd" color="text.primary" mt="10px">
				{heading}
			</Text>
			<Text variant="bodysm" color="text.secondary" sx={textSx}>
				{text}
			</Text>
		</Flex>
	</Wrapper>
);

export default React.memo(Slide);
